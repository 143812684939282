<template>
  <div>
    <b-card no-body>
      <b-card-body class="">
        <b-card-title class="d-flex justify-content-between align-items-center mb-3">
          <div>
            Deal ID - {{ $route.params.id }}
          </div>
          <span v-if="isBtn && deal.status === 'dispute_open'" class="d-flex">
            <b-button
              v-if="deal.responsible && userInfo.id === deal.responsible.id"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="mt-auto mr-1"
              type="button"
              variant="primary"
              @click="toChat()"
            >
              <span class="text-white" v-text="`To chat`" />
            </b-button>
            <b-button
              v-else
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="mt-auto mr-1"
              type="button"
              variant="primary"
              :disabled="deal.responsible"
              @click="handleDealChat()"
            >
              <span class="text-white" v-text="'Join the chat'" />
            </b-button>
          </span>
        </b-card-title>

        <div class="info-deal">
          <div class="info-deal__col">
            <div class="mb-3">
              <div class="info-deal__title">Buyer</div>
              <div class="info-deal__text">
                {{ deal.buyer.telegram_username ? deal.buyer.telegram_username : deal.buyer.name || '' }}
              </div>
              <div class="d-flex align-items-center g-15">
                <div class="d-flex align-items-center g-5">
                  <feather-icon icon="StarIcon" size="15" class="text-primary fill-current" />
                  {{ deal.buyer.rating || 0 }}
                </div>
                <div class="circle" />
                <div class="d-flex align-items-center g-5">
                  {{ deal.buyer.positiveComments + deal.buyer.negativeComments }} reviews
                </div>
              </div>
            </div>
            <div class="mb-3">
              <div class="info-deal__title">Seller</div>
              <div class="info-deal__text">
                {{ deal.seller.telegram_username ? deal.seller.telegram_username : deal.seller.name }}
              </div>
              <div class="d-flex align-items-center g-15">
                <div class="d-flex align-items-center g-5">
                  <feather-icon icon="StarIcon" size="15" class="text-primary fill-current" />
                  {{ deal.seller.rating || 0 }}
                </div>
                <div class="circle" />
                <div class="d-flex align-items-center g-5">
                  {{ deal.seller.positiveComments + deal.seller.negativeComments }} reviews
                </div>
              </div>
            </div>
          </div>
          <div class="info-deal__col">
            <div class="mb-3">
              <div class="info-deal__title">Time</div>
              <div class="info-deal__text">{{ formatDate(deal.createdAt) }}</div>
            </div>
            <div class="mb-3">
              <div class="info-deal__title">Amount</div>
              <div class="info-deal__text">{{ `${$decimal(deal.amount).toDecimalPlaces(2)} ${deal.fiat.code}` }}</div>
            </div>
            <div class="mb-3">
              <div class="info-deal__title">Price</div>
              <div class="info-deal__text">{{ `${$decimal(deal.price).toDecimalPlaces(2)} ${deal.fiat.code}` }}</div>
            </div>
            <div class="mb-3">
              <div class="info-deal__title">Quantity</div>
              <div class="info-deal__text">{{ `${deal.quantity} ${deal.crypto.symbol}` }}</div>
            </div>
            <div class="mb-3">
              <div class="info-deal__title">Fee</div>
              <div class="info-deal__text">{{ `${deal.fee} ${deal.crypto.symbol}` }}</div>
            </div>
          </div>
          <div class="info-deal__col">
            <div class="mb-3">
              <div class="info-deal__title">Payment method</div>
              <div class="info-deal__text">{{ deal.paymentMethod.name }}</div>
            </div>
            <div class="mb-3">
              <div class="info-deal__title">Order type</div>
              <div class="info-deal__text">{{ deal.orderType }}</div>
            </div>
            <div class="mb-3">
              <div class="info-deal__title">Payment duration</div>
              <div class="info-deal__text">{{ (deal.paymentDuration / 60) }} min</div>
            </div>
            <div class="mb-3">
              <div class="info-deal__title">Deal status</div>
              <b-badge :variant="getStatusColor(deal.status)" class="text-white">
                {{ getStatusName(deal.status) || '' }}
              </b-badge>
            </div>
          </div>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BAvatar,
  BCardBody,
  BCardTitle,
  BButton,
  BBadge,

} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapGetters } from 'vuex'
import mixinDeals from './../mixins/mixinDeals'

export default {
  name: 'InfoDeal',
  components: {
    BCard,
    BAvatar,
    BCardBody,
    BCardTitle,
    BButton,
    BBadge,
  },
  props: {
    deal: {
      type: Object,
      default: () => {},
    },
    isBtn: {
      type: Boolean,
      default: false,
    },
  },
  directives: {
    Ripple,
  },
  mixins: [mixinDeals],
  computed: {
    ...mapGetters({
      userInfo: 'auth/admin',
    }),
  },

  mounted() {},
  methods: {
    handleDealChat() {
      this.$emit('handleDealChat')
    },
    toChat() {
      this.$emit('toChat')
    },
  },
}
</script>

<style lang="scss" scoped>
.info-deal {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1rem;
  width: 70%;
  &__title {
    font-size: 0.9rem;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 1px;
    color: #718096;
    text-transform: uppercase;
  }
  &__text {
    font-size: 1rem;
    font-weight: 500;
    line-height: 24px;
    color: #1E2329;
  }
}

.g-5 {
  gap: 5px;
}
.g-15 {
  gap: 15px;
}
.circle {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #718096;
}
.dark-layout {
  .info-deal {
    &__text {
      color: #FFFFFF;
    }
  }
}
</style>