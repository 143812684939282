<template>
  <div>
    <InfoDeal
      v-if="isLoadingDeal"
      :deal="deal"
      isBtn
      @toChat="toChat"
      @handleDealChat="handleDealChat"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import waitRequest from '@/mixins/waitRequest'
import InfoDeal from './components/infoDeal.vue'

export default {
  name: 'P2pDealsInfo',
  components: {
    InfoDeal,
  },
  mixins: [waitRequest],
  data() {
    return {
      isLoadingDeal: false,
    }
  },
  computed: {
    ...mapGetters({
      deal: 'deals/deal',
    }),
  },
  watch: {
    deal() {
      this.isLoadingDeal = true
    },
  },
  mounted() {
    this.fetchDeal()
  },
  methods: {
    ...mapActions({
      fetchDealById: 'deals/fetchDealById',
      addModerator: 'deals/addModerator',
    }),
    handleDealChat() {
      this.showMsgBoxTwo(this.deal)
    },
    toChat() {
      this.$router.push(`/p2p-deal/chat/${this.deal.id}`)
    },

    showMsgBoxTwo(deal) {
      this.boxTwo = ''
      this.$bvModal
        .msgBoxConfirm('Please note that this dispute is reserved for you. You need to process this dispute, make a decision in favor of the buyer or seller and close the deal.', {
          title: 'Join the chat',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Join the chat',
          cancelTitle: 'Back',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.add(deal)
          }
        })
    },
    add(deal) {
      this.waitRequest(() => {
        return this.addModerator({ dealId: +deal.id })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Moderator is added',
                icon: 'UserPlusIcon',
                variant: 'info',
              },
            })
            this.toChat(deal)
          })
          .catch(this.checkErrors)
      })
    },
    fetchDeal() {
      const dealId = this.$route.params.id
      return this.waitRequest(() => this.fetchDealById(dealId).catch(this.checkErrors))
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
